<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-top-transition"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        class="d-inline-block d-md-none"
        v-on="on"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-container>
        <v-row style="position:fixed; width:100%; z-index:100; background-color:#1E1E1E">
          <v-col class="text-right">
            <v-btn
              class="mx-2"
              icon
              @click="dialog = false"
            >
              <v-icon size="36">
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              :loading="loading"
              class="px-2"
              height="42"
              placeholder="Type to search"
            />
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-row>
              <v-btn v-if="showLeftArrow" small icon @click.stop.prevent="scrollLeft">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <div class="buttons-line">
                <v-btn small class="mr-2" :outlined="enableMovieResults" @click.stop.prevent="toggleResults('movie')">
                  <v-icon class="mr-2" small>
                    mdi-movie
                  </v-icon>
                  Movies
                </v-btn>
                <v-btn small class="mr-2" :outlined="enableTVResults" @click.stop.prevent="toggleResults('tv_show')">
                  <v-icon class="mr-2" small>
                    mdi-television-classic
                  </v-icon>
                  TV Shows
                </v-btn>
                <v-btn small class="mr-2" :outlined="enableBookResults" @click.stop.prevent="toggleResults('book')">
                  <v-icon class="mr-2" small>
                    mdi-book
                  </v-icon>
                  Books
                </v-btn>
                <v-btn small class="mr-2" :outlined="enableGameResults" @click.stop.prevent="toggleResults('game')">
                  <v-icon class="mr-2" small>
                    mdi-controller
                  </v-icon>
                  Games
                </v-btn>
                <v-btn small class="mr-2" :outlined="enablePersonResults" @click.stop.prevent="toggleResults('person')">
                  <v-icon class="mr-2" small>
                    mdi-account-star
                  </v-icon>
                  Celebrities
                </v-btn>
                <v-btn small class="mr-2" :outlined="enableAuthorResults" @click.stop.prevent="toggleResults('author')">
                  <v-icon class="mr-2" small>
                    mdi-book-account
                  </v-icon>
                  Authors
                </v-btn>
              </div>
              <v-btn v-if="showRightArrow" small icon @click.stop.prevent="scrollRight">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row style="margin-top:200px; z-index:100;">
          <v-col>
            <v-list>
              <div
                v-for="(item, index) in results"
                :key="index"
              >
                <template v-if="item.source_name === `openlibrary`">
                  <NuxtLink :to="`/book/${item.slug}`" style="text-decoration:none">
                    <v-list-item>
                      <v-list-item-avatar tile height="90" width="60">
                        <Picture
                          class="rounded-lg"
                          :url="generateOpenLibraryImage(item.cover_id, 'ID', 'S')"
                          icon="mdi-television-classic"
                          :show-icon="false"
                          :alt-string="item.title"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.title }}
                          <v-icon small>
                            mdi-book
                          </v-icon>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <div v-if="item.first_publish_year">
                            {{ new Date(item.first_publish_year).getFullYear() }}
                          </div>
                          <!--                          <v-chip v-for="(author, index) in item.authors.slice(0,3)" :key="index" class="mr-1" label small>-->
                          <!--                            {{ author.name }}-->
                          <!--                          </v-chip>-->
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </NuxtLink>
                </template>
                <template v-else-if="item.source_name === `igdb`">
                  <NuxtLink :to="`/game/${item.slug}`" style="text-decoration:none">
                    <v-list-item>
                      <v-list-item-avatar tile height="90" width="60">
                        <Picture
                          class="rounded-lg"
                          :url="generateIGDBImage(item.cover_id, 'cover_small')"
                          icon="mdi-television-classic"
                          :show-icon="false"
                          :alt-string="item.title"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.title }}
                          <v-icon small>
                            mdi-controller
                          </v-icon>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <div v-if="item.first_release_date">
                            {{ new Date(Number(item.first_release_date.toString() + '000')).getFullYear() }}
                          </div>
                          <v-chip v-for="(genre, genreIndex) in item.genres" :key="genreIndex" class="mr-1" small>
                            {{ genre }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </NuxtLink>
                </template>
                <template v-else-if="item.source_name === `tmdb_movie`">
                  <NuxtLink :to="`/movie/${item.slug}`" style="text-decoration:none">
                    <v-list-item>
                      <v-list-item-avatar tile height="90" width="60">
                        <Picture
                          class="rounded-lg"
                          :url="generateTMDBImage(item.poster_id, 'w92')"
                          icon="mdi-television-classic"
                          :show-icon="false"
                          :alt-string="item.title"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.title }}
                          <v-icon small>
                            mdi-movie
                          </v-icon>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <div v-if="item.release_date">
                            {{ new Date(item.release_date).getFullYear() }}
                          </div>
                          <v-chip v-for="(genre, genreIndex) in item.genres" :key="genreIndex" class="mr-1" small>
                            {{ genre }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </NuxtLink>
                </template>
                <template v-else-if="item.source_name === `tmdb_tv`">
                  <NuxtLink :to="`/tv/${item.slug}`" style="text-decoration:none">
                    <v-list-item>
                      <v-list-item-avatar tile height="90" width="60">
                        <Picture
                          class="rounded-lg"
                          :url="generateTMDBImage(item.poster_id, 'w92')"
                          icon="mdi-television-classic"
                          :show-icon="false"
                          :alt-string="item.title"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.title }}
                          <v-icon small>
                            mdi-television-classic
                          </v-icon>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <div v-if="item.first_air_date">
                            {{ new Date(item.first_air_date).getFullYear() }}
                          </div>
                          <v-chip v-for="(genre, genreIndex) in item.genres" :key="genreIndex" class="mr-1" small>
                            {{ genre }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </NuxtLink>
                </template>
                <template v-else-if="item.source_name === `author`">
                  <NuxtLink :to="`/author/${item.slug}`" style="text-decoration:none; width:100%">
                    <v-list-item style="max-width: 500px">
                      <v-list-item-avatar tile height="90" width="60">
                        <Picture
                          class="rounded-lg"
                          :url="generateOpenLibraryImage(item.photo_id, 'ID', 'S')"
                          icon="mdi-television-classic"
                          :show-icon="false"
                          :alt-string="item.name"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon small style="vertical-align: text-bottom;">
                            mdi-book-account
                          </v-icon>
                          {{ item.name }}
                        </v-list-item-title>
                        <!--              <v-list-item-subtitle>-->
                        <!--                <div v-if="data.item.birthday">-->
                        <!--                  {{ dateConvert(data.item.birthday) }}-->
                        <!--                </div>-->
                        <!--              </v-list-item-subtitle>-->
                      </v-list-item-content>
                    </v-list-item>
                  </NuxtLink>
                </template>
                <template v-else-if="item.source_name === `person`">
                  <NuxtLink :to="`/person/${item.slug}`" style="text-decoration:none; width:100%">
                    <v-list-item style="max-width: 500px">
                      <v-list-item-avatar tile height="90" width="60">
                        <Picture
                          class="rounded-lg"
                          :url="generateTMDBImage(item.profile_id, 'w92')"
                          icon="mdi-television-classic"
                          :show-icon="false"
                          :alt-string="item.name"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon small style="vertical-align: text-bottom;">
                            mdi-account-star
                          </v-icon>
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <div v-if="item.birthday">
                            {{ dateConvert(item.birthday) }}
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </NuxtLink>
                </template>
              </div>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import Search from "@/services/search";
import image from "@/mixins/image";
import time from "@/mixins/time";

export default {
  name: "SearchToolbar",
  components: {
    Picture: () => import("@/components/blocks/Picture.vue"),
  },
  mixins: [image, time],
  data () {
    return {
      search: "",
      results: [],
      dialog: false,
      enableMovieResults: false,
      enableTVResults: false,
      enableGameResults: false,
      enableBookResults: false,
      enablePersonResults: false,
      enableAuthorResults: false,
      //
      loading: false,
      //
      //
      showLeftArrow: true,
      showRightArrow: true,
      //
      requests: [],
    };
  },
  watch: {
    search (value) {
      value && this.querySelections(value);
    },
    "$route.path" () {
      this.dialog = false;
    },
  },
  methods: {
    scrollRight () {
      const elmnt = document.getElementsByClassName("buttons-line")[0];
      elmnt.scrollLeft += 200;
    },
    scrollLeft () {
      const elmnt = document.getElementsByClassName("buttons-line")[0];
      elmnt.scrollLeft -= 200;
    },
    querySelections: _.debounce(function (value) {
      if (!value) { return; }

      const sources = [];
      if (this.enableMovieResults) { sources.push("movie"); }
      if (this.enableTVResults) { sources.push("tv"); }
      if (this.enableGameResults) { sources.push("game"); }
      if (this.enableBookResults) { sources.push("book"); }
      if (this.enablePersonResults) { sources.push("person"); }
      if (this.enableAuthorResults) { sources.push("author"); }

      this.loading = true;
      this.requests.push(this.search);
      const searchAPI = new Search(this.$axios);
      searchAPI.universalSearch(this.search, sources).then((result) => {
        const lastSearchInput = this.requests[this.requests.length - 1];
        if (lastSearchInput === result.query) {
          this.results = [{ name: "barForToggles", disabled: true }, ...result.items];
          this.requests = [];
          this.loading = false;
        }
      });
    }, 200),
    toggleResults (source_name) {
      if (source_name === "movie") {
        this.enableMovieResults = !this.enableMovieResults;
      } else if (source_name === "tv_show") {
        this.enableTVResults = !this.enableTVResults;
      } else if (source_name === "game") {
        this.enableGameResults = !this.enableGameResults;
      } else if (source_name === "book") {
        this.enableBookResults = !this.enableBookResults;
      } else if (source_name === "person") {
        this.enablePersonResults = !this.enablePersonResults;
      } else if (source_name === "author") {
        this.enableAuthorResults = !this.enableAuthorResults;
      }

      if (!this.search) { return; }

      const sources = [];
      if (this.enableMovieResults) { sources.push("movie"); }
      if (this.enableTVResults) { sources.push("tv"); }
      if (this.enableGameResults) { sources.push("game"); }
      if (this.enableBookResults) { sources.push("book"); }
      if (this.enablePersonResults) { sources.push("person"); }
      if (this.enableAuthorResults) { sources.push("author"); }

      this.loading = true;
      this.requests.push(this.search);
      const searchAPI = new Search(this.$axios);
      searchAPI.universalSearch(this.search, sources).then((result) => {
        const lastSearchInput = this.requests[this.requests.length - 1];
        if (lastSearchInput === result.query) {
          this.results = [{ name: "barForToggles", disabled: true }, ...result.items];
          this.requests = [];
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style scoped>
/* Hide scrollbar for IE, Edge and Firefox */
.buttons-line {
  width: 80%;
  overflow-x: scroll;
  flex: inherit;
  white-space: nowrap;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.buttons-line::-webkit-scrollbar {
  display: none;
}
</style>
